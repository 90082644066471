import { Textarea } from '@/components/ui/textarea';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { EventComment } from '@/features/event/types';
import { CommentListItem } from './CommentListItem';
import { useManagers } from '../api/getManagers';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { cn } from '@/utils';
// import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
// import { sections } from '../data/sections';
// import { ScrollArea } from '@/components/ui/scroll-area';
// import { TagTextInput } from './tag-text-input';

interface CommentFieldProps {
  field: any;
  comments: EventComment[];
}

export function CommentField({ field, comments }: CommentFieldProps) {
  // const [tagMenuOpen, setTagMenuOpen] = useState<boolean>(false);

  const { data: managers } = useManagers({});

  const historicalComments = comments?.map(comment => ({
    ...comment,
    author: managers?.find(manager => manager.managerId === comment.userId)?.username ?? '',
    inserted: convertUTCtoLocal(comment.inserted)
  }));

  // const currentComment = historicalComments[0];

  return (
    <div>
      <Tabs defaultValue="current" className="w-full">
        <TabsList className="bg-zinc-200">
          <TabsTrigger value="current" className="text-xs">
            <Label name="current_comment_tab_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </TabsTrigger>
          <TabsTrigger value="history" className="text-xs">
            <Label name="comment_history_tab_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </TabsTrigger>
        </TabsList>
        <TabsContent value="current" className="space-y-2">
          <Textarea
            className={cn('w-full')}
            autoHeight
            // defaultValue={currentComment?.logText?.text}
            onChange={e => field.onChange(e.target.value)}
          />
          {/* <TagTextInput value={field.value} onChange={field.onChange} /> */}
        </TabsContent>
        <TabsContent value="history" className=" space-y-2">
          {historicalComments?.length === 0 && (
            <div>
              <Label name="no_comment_history" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
            </div>
          )}
          <Collapsible className="space-y-2">
            {historicalComments?.splice(0, 5)?.map(comment => (
              <CommentListItem key={comment.eventLogId} comment={comment} />
            ))}
            <CollapsibleContent className="space-y-2">
              {historicalComments.map(comment => (
                <CommentListItem key={comment.eventLogId} comment={comment} />
              ))}
            </CollapsibleContent>
            {historicalComments.length > 1 && (
              <CollapsibleTrigger>
                <Label name="show_all_comments" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </CollapsibleTrigger>
            )}
          </Collapsible>
        </TabsContent>
      </Tabs>
    </div>
  );
}
