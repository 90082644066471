import { FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { label } from '@/utils/label';
import { useSettings } from '@/features/settings/api/getSettings';
import { useFormContext } from 'react-hook-form';
import { Asterisk } from '@/components/ui/asterisk';
import { ToggleGroup } from '@radix-ui/react-toggle-group';
import { ToggleGroupItem } from '@/components/ui/toggle-group';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { useMe } from '@/features/auth/api/get-me';
import { useEventStore } from '@/stores/eventStore';
import { CommentField } from '@/features/admin/components/CommentField';
import { EventCategory } from '../../types';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

export function CategorySection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const form = useFormContext();
  const { data: settings } = useSettings({});

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const eventCategories = settings?.eventCategory ?? [];

  const handleCategoryChange = (category: string) => {
    // const eventTimes = form.getValues('times').map(({ eventEnd, eventStart, ...rest }: any) => {
    //   return {
    //     ...rest,
    //     eventStart: eventStart.includes('T') ? eventStart.split('T')[0] : eventStart?.split(' ')[0],
    //     eventEnd: eventEnd.includes('T') ? eventEnd.split('T')[0] : eventEnd?.split(' ')[0]
    //   };
    // });

    // const newEventTimes = eventTimes?.length > 1 ? [{ ...eventTimes[0] }] : eventTimes;

    form.setValue('category', category, { shouldDirty: true });
    form.setValue('times', [{ eventTimeId: 0, eventId: form.watch('eventId'), inserted: '', eventStart: '', eventEnd: '' }], { shouldDirty: true });

    if (category === EventCategory.TRAFFPUNKT) {
      form.setValue('persons', [], { shouldDirty: true });
    }
  };

  const comments = useEventStore(state => state.comments);
  const generalComments = comments?.filter(comment => comment.logText.field === 'general');

  if (!settings) return null;

  return (
    <div className="space-y-6">
      {user?.role !== UserType.ORGANIZER && (
        <FormField
          control={form.control}
          name="generalComment"
          render={({ field }) => (
            <FormItem className="bg-card rounded-md p-4 px-4 border">
              <FormLabel>
                <Label name="comment_for_general" groupName={labelGroup} />
              </FormLabel>
              <FormControl className="w-[300px]">
                <CommentField field={field} comments={generalComments ?? []} />
              </FormControl>
            </FormItem>
          )}
        />
      )}
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'category'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="category">
            <CardHeader className="py-3">
              <AccordionTrigger className="text-md py-0">
                <CardTitle>
                  <Label name="event_category_details_title" groupName={labelGroup} />
                  <Asterisk className="ml-2 asterisk" />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <FormLabel>
                  <Label name="select_event_category" groupName={labelGroup} />
                  <Asterisk className="ml-2" />
                </FormLabel>
                <CardDescription>
                  <Label name="event_category_details_subtitle" groupName={labelGroup} />
                </CardDescription>
                <FormField
                  control={form.control}
                  name="category"
                  render={({ field }) => (
                    <FormItem id="event_category" className="flex items-start md:items-center flex-col md:flex-row gap-4">
                      <FormControl className="w-full">
                        <ToggleGroup type="single" className="w-full flex flex-wrap gap-y-2" onValueChange={value => handleCategoryChange(value)} value={field.value}>
                          {eventCategories.map(({ value }) => (
                            <ToggleGroupItem key={value} value={value} className="border mx-1 data-[state=on]:bg-brand h-8 md:h-10 data-[state=on]:border-brand data-[state=on]:text-white" aria-label={label(`toggle_event_category_${value}`, labelGroup)}>
                              <FormLabel className="font-semibold text-sm ">
                                <Label name={`toggle_event_category_${value}`} groupName={labelGroup} />
                              </FormLabel>
                            </ToggleGroupItem>
                          ))}
                        </ToggleGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
