import { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';

import { TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from './button';
import { useState } from 'react';
import { Input } from './input';
// import { Checkbox } from './checkbox';
import { label } from '@/utils/label';
import { DataTablePagination } from './data-table-pagination';
import { DataExportDropdown } from './data-export-dropdown';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { ScrollBar } from './scroll-area';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  columnVisibility?: VisibilityState;
  config?: {
    disableExport?: boolean;
    maxHeight?: string;
    exportOptions?: {
      excel?: boolean;
      csv?: boolean;
    };
    getSearchFields: (row: Row<any>) => string[];
    onSearchChange?: (value: string) => void;
    getLabel?: (text: string) => string;
  };
  Actions?: (props: { table: ReturnType<typeof useReactTable> }) => JSX.Element;
  FacetedFilters?: (props: { table: ReturnType<typeof useReactTable> }) => JSX.Element;
}

const defaultColumns: ColumnDef<any, any>[] = [
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
];

export function DataTable<TData, TValue>({
  columns,
  data,
  columnVisibility: initialColumnVisibility,
  config = {
    getSearchFields: () => [],
    maxHeight: 'calc(100lvh - 270px)',
    onSearchChange: () => {},
    disableExport: false,
    getLabel: (text: string) => text,
    exportOptions: {
      excel: true,
      csv: true
    }
  },
  FacetedFilters,
  Actions
}: DataTableProps<TData, TValue>) {
  const [filtering, setFiltering] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility ?? {});
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns: [...defaultColumns, ...columns],
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter: filtering
    },
    onGlobalFilterChange: setFiltering,
    globalFilterFn: (row, _, filterValue) => {
      const filterstring = config.getSearchFields(row);
      const searchParts = filterValue.split(' ');

      return searchParts.every((searchPart: string) => filterstring.some(value => value?.toLowerCase().includes(searchPart.toLowerCase())));
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  });

  const { disableExport } = config;

  return (
    <div>
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center w-full">
          <Input
            placeholder={label('table_search_input_placeholder', LabelGroup.GLOBAL)}
            className="max-w-sm"
            onChange={event => {
              setFiltering(event.target.value);
              config?.onSearchChange?.(event.target.value);
            }}
          />
          {!!FacetedFilters && <FacetedFilters table={table} />}
        </div>

        <div className="flex items-center gap-x-2">
          {!!Actions && <Actions table={table} />}
          {!disableExport && <DataExportDropdown table={table} getLabel={config.getLabel as (text: string) => string} />}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                <Label name="columns_toggle_button" groupName={LabelGroup.GLOBAL} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-48" align="end">
              {table
                .getAllColumns()
                .filter(column => column.getCanHide())
                .map(column => {
                  return (
                    <DropdownMenuCheckboxItem key={column.id} className="capitalize" checked={column.getIsVisible()} onCheckedChange={value => column.toggleVisibility(!!value)}>
                      <Label name={column.id} groupName="table.column" />
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <ScrollArea style={{ maxHeight: config.maxHeight ?? 'calc(100lvh - 270px)' }} className="rounded-md relative  border bg-card overflow-y-auto">
        <table className="relative text-sm border-collapse w-full">
          <TableHeader className="">
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead key={header.id} className="sticky bg-zinc-50 top-0  z-20 ">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  <Label name="no_data_found" groupName={LabelGroup.GLOBAL} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <DataTablePagination table={table} size={filtering ? table.getFilteredRowModel().rows.length : table.getCoreRowModel().rows.length} />
    </div>
  );
}
