export const validateUrl = (value: string): boolean => {
  try {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    const url = new URL(value); // Validates URL format
    return (url.protocol === 'http:' || url.protocol === 'https:') && pattern.test(value); // Ensures it's http or https
  } catch {
    return false; // If parsing fails, it's not a valid URL
  }
};
