import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { eventReviewSchema } from '../data/event-review-schema';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useEvent } from '../api/getEvent';
import { UserType } from '@/features/user';
import { Clock, Files } from 'lucide-react';
import { useUpdateReview } from '../api/updateReview';
import { useEventStore } from '@/stores/eventStore';
import { parseEventLogToComments } from '@/utils/parseEventLogToComments';
import { MangeEventSkeleton } from '../components/MangeEventSkeleton';
import { EventCategory, EventStatus } from '@/features/event/types';
import { OverviewMenu } from '../components/overview-menu';
import { sections } from '../data/sections';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { HistoryTimeline } from '../components/(history-timeline)/history-timeline';
import { OrganizerDetails } from '../components/organizer-details';
import { useMe } from '@/features/auth/api/get-me';
import { CommentField } from '../components/CommentField';
import { Comment } from '@/features/event/components/comment';
import { Pricing } from '../components/pricing';
import { AssignManager } from '../components/AssignManager';
import { ReturnDueDialog } from '../components/return-due-dialog';
import { useManagers } from '../api/getManagers';
import { EventStatusDropDown } from '../components/event-status-dropdown';
import { useStartSession } from '../../event/api/start-session';
import { VersionComparer } from '../components/version-comparer';
import { Spinner } from '@/components/ui/spinner';
import { formatDate } from '@/utils/formatDate';
import { NotifyChange } from '../components/notify-change';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { useEndSession } from '../../event/api/end-session';
import { ActiveEditorAlertDialog } from '../components/active-editor-alert-dialog';
import { PreviewSection } from '@/features/event/components/(sections)/preview-section';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/utils';
import { useConfirm } from '@/providers/confirm';
import { label } from '@/utils/label';
import { useToast } from '@/components/ui/use-toast';
import { isDateNotDue } from '@/utils/is-date-not-due';

export function ManageEvent() {
  const { id } = useParams();

  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false);

  const processingViewRef = useRef(null);
  const scrollInterval = useRef<any>(null);

  const setEventComments = useEventStore(state => state.setComments);
  const navigate = useNavigate();
  const { toast } = useToast();

  const comments = useEventStore(state => state.comments);

  const { data: managers } = useManagers({});
  const { data: user } = useMe({});

  const form = useForm<z.infer<typeof eventReviewSchema>>({
    resolver: zodResolver(eventReviewSchema)
  });

  const {
    data: event,
    isLoading,
    isFetched
  } = useEvent({
    eventId: Number(id),
    config: {
      enabled: !!id,
      onSuccess: data => {
        setEventComments(parseEventLogToComments(data?.logs ?? []));

        form.reset(
          {
            notify: null,
            organizerId: (data?.draft ?? data)?.organizerId ?? 0,
            status: (data?.draft ?? data)?.draft?.status ?? (data?.draft ?? data)?.status ?? 0,
            yearId: (data?.draft ?? data)?.yearId ?? 0,
            times: (data?.draft ?? data)?.times ?? [],
            title: (data?.draft ?? data)?.title ?? '',
            description: (data?.draft ?? data)?.description ?? '',
            category: (data?.draft ?? data)?.category ?? '',
            eventType: (data?.draft ?? data)?.eventType ?? '',
            languages: typeof (data?.draft ?? data)?.languages === 'string' ? (data?.draft ?? data)?.languages?.split(';') : [],
            organizationType: (data?.draft ?? data)?.organizationType ?? '',
            topic: (data?.draft ?? data)?.topic ?? '',
            topic2: (data?.draft ?? data)?.topic2 ?? '',
            socialIssue: (data?.draft ?? data)?.socialIssue ?? '',
            eventId: (data?.draft ?? data)?.eventId ?? 0,
            locationId: (data?.draft ?? data)?.locationId ?? 0,
            locationDescription: (data?.draft ?? data)?.locationDescription ?? '',
            contactPerson1Email: (data?.draft ?? data)?.contactPerson1Email ?? '',
            contactPerson1Name: (data?.draft ?? data)?.contactPerson1Name ?? '',
            contactPerson1Telephone: (data?.draft ?? data)?.contactPerson1Telephone ?? '',
            contactPerson1Title: (data?.draft ?? data)?.contactPerson1Title ?? '',
            contactPerson2Email: (data?.draft ?? data)?.contactPerson2Email ?? '',
            contactPerson2Name: (data?.draft ?? data)?.contactPerson2Name ?? '',
            contactPerson2Telephone: (data?.draft ?? data)?.contactPerson2Telephone ?? '',
            contactPerson1Organization: (data?.draft ?? data)?.contactPerson1Organization ?? '',
            contactPerson2Organization: (data?.draft ?? data)?.contactPerson2Organization ?? '',
            showEmail: (data?.draft ?? data)?.showEmail ?? false,
            showTelephone: (data?.draft ?? data)?.showTelephone ?? false,
            url1: (data?.draft ?? data)?.url1 ?? '',
            url2: (data?.draft ?? data)?.url2 ?? '',
            url3: (data?.draft ?? data)?.url3 ?? '',
            facebookUrl: (data?.draft ?? data)?.facebookUrl ?? '',
            xurl: (data?.draft ?? data)?.xurl ?? '',
            linkedInUrl: (data?.draft ?? data)?.linkedInUrl ?? '',
            persons: (data?.draft ?? data)?.persons ?? [],
            organizers: (data?.draft ?? data)?.organizers ?? [],
            digitalStreamingType: (data?.draft ?? data)?.digitalStreamingType ?? '',
            digitalStreamingUrl: (data?.draft ?? data)?.digitalStreamingUrl ?? '',
            digitalMeetingUrl: (data?.draft ?? data)?.digitalMeetingUrl ?? '',
            digitalMeetingDescription: (data?.draft ?? data)?.digitalMeetingDescription ?? '',
            digitalArchiveUrl: (data?.draft ?? data)?.digitalArchiveUrl ?? '',
            accessibilityText: (data?.draft ?? data)?.accessibilityText ?? false,
            accessibilityTeleloop: (data?.draft ?? data)?.accessibilityTeleloop ?? false,
            accessibilityVisualInterpretation: (data?.draft ?? data)?.accessibilityVisualInterpretation ?? false,
            accessibilityWheelchairToilet: (data?.draft ?? data)?.accessibilityWheelchairToilet ?? false,
            accessibilityWheelchairVenue: (data?.draft ?? data)?.accessibilityWheelchairVenue ?? false,
            accessibilitySignLanguage: (data?.draft ?? data)?.accessibilitySignLanguage ?? false,
            environmentStationary: (data?.draft ?? data)?.environmentStationary ?? false,
            environmentPrint: (data?.draft ?? data)?.environmentPrint ?? false,
            environmentFlyer: (data?.draft ?? data)?.environmentFlyer ?? false,
            environmentBattery: (data?.draft ?? data)?.environmentBattery ?? false,
            environmentPlastic: (data?.draft ?? data)?.environmentPlastic ?? false,
            environmentRecycling: (data?.draft ?? data)?.environmentRecycling ?? false,
            environmentDisposable: (data?.draft ?? data)?.environmentDisposable ?? false,
            environmentSourceSorting: (data?.draft ?? data)?.environmentSourceSorting ?? false,
            environmentFoodEcological: (data?.draft ?? data)?.environmentFoodEcological ?? false,
            environmentFoodLocallyProduced: (data?.draft ?? data)?.environmentFoodLocallyProduced ?? false,
            environmentFoodEthical: (data?.draft ?? data)?.environmentFoodEthical ?? false,
            environmentWater: (data?.draft ?? data)?.environmentWater ?? false,
            environmentNoFood: (data?.draft ?? data)?.environmentNoFood ?? false,
            environmentServiceQuestion: (data?.draft ?? data)?.environmentServiceQuestion ?? false,
            environmentServiceElectricity: (data?.draft ?? data)?.environmentServiceElectricity ?? false,
            inserted: (data?.draft ?? data)?.inserted ?? '',
            title_lastVersion: (data?.draft ?? data)?.title_lastVersion ?? '',
            description_lastVersion: (data?.draft ?? data)?.description_lastVersion ?? '',
            socialIssue_lastVersion: (data?.draft ?? data)?.socialIssue_lastVersion ?? '',
            titleComment: '',
            descriptionComment: '',
            socialIssueComment: '',
            generalComment: '',
            noteComment: '',
            returnDue: (data?.draft ?? data)?.returnDue ?? null,
            returnDate: (data?.draft ?? data)?.returnDate ?? null,
            noCost: (data?.draft ?? data)?.noCost ?? false,
            noCostDeadline: (data?.draft ?? data)?.noCostDeadline ?? null
          },
          {
            keepDirty: false
          }
        );
      }
    }
  });

  const { data: organizer } = useOrganization({
    organizerId: Number(event?.organizerId),
    config: {
      enabled: !!event?.organizerId
    }
  });

  const { mutateAsync: updateReview, isLoading: isUpdating } = useUpdateReview({ eventId: Number(id) });
  const { mutateAsync: reviewEvent, isError } = useStartSession({ eventId: Number(id) });
  const { mutateAsync: endEventReview } = useEndSession({ eventId: Number(id) });

  const hasEditor = !!event?.draft?.activeEditorId || !!event?.activeEditorId;
  const isEditor = (event?.draft?.activeEditorId || event?.activeEditorId) === user?.userId;
  const activeEditor = managers?.find(manager => manager.managerId === (event?.draft?.activeEditorId ?? event?.activeEditorId));
  const isAnyFieldDirty = Object.keys(form.formState.dirtyFields).length > 0;

  useConfirm(
    {
      title: 'there_are_unsaved_changes',
      silent: !isAnyFieldDirty && isEditor,
      onConfirm: () => isEditor && event?.eventId && endEventReview(event?.eventId ?? 0)
    },
    isAnyFieldDirty || isEditor
  );

  useEffect(() => {
    const handleBeforeUnload = (_: any) => {
      if (isEditor && event?.eventId && !form.formState.isDirty && !form.formState.isSubmitted && !form.formState.isSubmitting) {
        // Execute your custom function silently
        endEventReview(event.eventId);
        return undefined;
      }

      if (form.formState.isDirty && !form.formState.isSubmitted && !form.formState.isSubmitting) {
        return 'You have unsaved changes. Are you sure you want to leave?';
      } else {
        return undefined;
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEditor, event?.eventId, form.formState.isDirty, form.formState.isSubmitted, form.formState.isSubmitting]);

  const handleSubmit = async (values: z.infer<typeof eventReviewSchema>) => {
    try {
      console.log('submitting');
      // If the status is changed to ActionRequired or Rejected and the event has a cost, the returnDue field must be filled out.
      if ((event?.status !== values.status || values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected) && !values?.noCost && (values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected) && values.returnDue === null && !event?.draft) {
        return;
      }

      // If the status is changed to ActionRequired or Rejected the user must fill out the notify field. (send email to organizer)
      if (values.notify === null && (values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected || values.status === EventStatus.Approved)) {
        setShowNotifyDialog(true);
        return;
      }

      const shouldDelete = values.status === EventStatus.Approved && !!event?.draft;
      const shouldNavigate = values.status !== event?.status || shouldDelete;

      await updateReview({ ...values } as any);

      if (shouldNavigate) {
        if (!shouldDelete) {
          await endEventReview(values?.eventId ?? 0);
        }

        navigate(`/events#pending`);
      } else {
        form.reset({
          notify: null,
          descriptionComment: '',
          socialIssueComment: '',
          generalComment: '',
          titleComment: ''
        });

        console.log(form.formState.isDirty, 'insSub');

        toast({ variant: 'success', title: label('event_review_updated', LabelGroup.MANAGER_EVENT_HANDLING) });
      }

      setShowNotifyDialog(false);
    } catch (error) {}
  };

  const handleStartSesion = async (eventId: number) => {
    try {
      await reviewEvent(eventId);
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      form.reset();
    };
  }, []);

  useEffect(() => {
    const startScrolling = (direction: string) => {
      scrollInterval.current = setInterval(() => {
        (processingViewRef.current as any)?.scrollBy({
          top: direction === 'down' ? 80 : -80 // Adjust scroll speed here
        });
      }, 50); // Adjust interval speed here for faster/slower scrolling
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    };

    const handleKeyDown = (event: any) => {
      if (!scrollInterval.current) {
        if (event.key === 'ArrowDown') {
          startScrolling('down');
        } else if (event.key === 'ArrowUp') {
          startScrolling('up');
        }
      }
    };

    const handleKeyUp = (event: any) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        stopScrolling();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      stopScrolling();
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const noteComments = comments?.filter(comment => comment.logText.field === 'note');
  const hasNoCostDeadline = isDateNotDue(form.watch('noCostDeadline') ?? '');
  const showReturnDueDialog = !event?.draft && !form.watch('noCost') && !hasNoCostDeadline && (form.watch('status') === EventStatus.ActionRequired || form.watch('status') === EventStatus.Rejected) && form.watch('returnDue') === null && form.formState.isDirty;

  if (isLoading && !isFetched) return <MangeEventSkeleton />;

  return (
    <div className="overflow-hidden max-h-[calc(100lvh)]">
      {hasEditor && !isEditor && activeEditor && isError && <ActiveEditorAlertDialog editorName={activeEditor?.name !== '' ? activeEditor?.name : activeEditor?.username ?? ''} onLockup={() => endEventReview(event?.eventId ?? 0)} isAdmin={user?.role === UserType.ADMIN} />}
      <Form {...form}>
        <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)}>
          {form.formState.isSubmitted && !form.formState.isSubmitting && showReturnDueDialog && <ReturnDueDialog onSubmit={() => form.handleSubmit(handleSubmit)()} form={form} previousStatus={event?.status} />}
          {showNotifyDialog && <NotifyChange form={form} onSubmit={() => form.handleSubmit(handleSubmit)()} dialog={true} />}

          <Tabs defaultValue={'processing'} className="w-full">
            <div className="space-y-4 p-4 px-6  bg-white border-b ">
              <div className="space-y-2">
                <div className="flex justify-between ">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-xl">
                      <Label name="overview_menu_event_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} /> {event?.draftParentId ?? event?.eventId}
                    </span>
                  </div>

                  <div className="flex gap-2 items-center">
                    <div className="w-fit">
                      <AssignManager event={event} className="w-full" />
                    </div>
                    <div className="flex gap-x-2 mt-0 items-center">
                      <FormField
                        control={form.control}
                        name="status"
                        render={({ field }) => (
                          <FormItem className="w-64">
                            <FormControl className="w-full">
                              <EventStatusDropDown disabled={!isEditor} field={field} isChange={!!event?.draft} />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {isEditor && hasEditor ? (
                        <Button size="sm" variant="primary" disabled={isLoading || !isAnyFieldDirty} type="submit">
                          {isUpdating && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
                          {!isUpdating ? <Label name="update" groupName={LabelGroup.MANAGER_EVENT_HANDLING} /> : <Label name="updating" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />}
                        </Button>
                      ) : (
                        <div className="flex justify-start">
                          <Button onClick={() => event?.eventId && handleStartSesion(event?.draft?.eventId ?? event?.eventId)} type="button" className="h-9 mr-full">
                            <Label name="assign_myself_as_assigne_button_text" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Link className="underline" to={`/organizations/${event?.organizerId ?? 0}`}>
                  {organizer?.organizerName}
                </Link>
              </div>
              <div className="flex items-center w-full justify-between">
                <TabsList>
                  <TabsTrigger value="processing">
                    <Label name="processing_tab_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                  </TabsTrigger>
                  <TabsTrigger value="history">
                    <Label name="history_tab_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                  </TabsTrigger>
                  <TabsTrigger value="pricing">
                    <Label name="pricing_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
                  </TabsTrigger>
                  <TabsTrigger value="version_comparer" className={cn(event?.draft === null && 'hidden')} hidden={event?.draft === null}>
                    <Label name="version_comparer_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
                  </TabsTrigger>
                </TabsList>
                <div className="flex gap-4">
                  {(event?.sentDate ?? event?.draft?.sentDate) && (
                    <div className="bg-sky-50 text-sky-900 text-sm flex items-center w-fit p-2 gap-2 px-2 rounded-md">
                      <Clock className="h-4 w-4  stroke-sky-500" />
                      <Label name="event_sent" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                      <span className="font-semibold -ml-1">{formatDate(event?.sentDate ?? event?.draft?.sentDate ?? '')}</span>
                    </div>
                  )}
                  {event?.draft && (
                    <div className="bg-brand/10 text-orange-900 text-sm flex items-center w-fit p-2 gap-2 px-2 rounded-md">
                      <Files className="h-4 w-4  stroke-brand" />
                      <Label name="event_draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <TabsContent value="processing" className="pl-6 mt-0">
              {isEditor ? (
                <div className="flex gap-2">
                  <div className="w-fit  h-auto flex">
                    <OverviewMenu form={form} isFetched={isFetched} />
                  </div>
                  <div ref={processingViewRef} className="h-[calc(100lvh_-_150px)] overflow-y-auto w-full relative processing-view">
                    <div className="space-y-8 p-4">
                      {sections
                        .filter(({ id }) => (id === 'persons-section' && form.watch('category') === EventCategory.TRAFFPUNKT ? false : true))
                        .map(({ id, component: Component = () => null }) => (
                          <div key={id} id={id} className={id === 'basicDetails' ? 'mt-4' : ''}>
                            <Component form={form} />
                          </div>
                        ))}
                      {isEditor ? (
                        <>
                          <FormField
                            control={form.control}
                            name="noteComment"
                            render={({ field }) => (
                              <FormItem className="bg-zinc-100 rounded-md p-4 px-4 border">
                                <FormLabel>
                                  <Label name="internal_note" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                                </FormLabel>
                                <FormControl className="w-[300px]">
                                  <CommentField field={field} comments={noteComments ?? []} />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </>
                      ) : (
                        <Comment comment={noteComments?.[0]} />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-[calc(100lvh_-_150px)] overflow-y-auto w-full relative processing-view">
                  <div className="space-y-8 p-4 pl-0">
                    <PreviewSection />
                  </div>
                </div>
              )}
            </TabsContent>
            <TabsContent value="history" className="p-4 h-[calc(100lvh_-_150px)] overflow-y-auto w-full mt-0">
              <HistoryTimeline />
            </TabsContent>
            <TabsContent value="pricing" className="p-4">
              <Pricing />
            </TabsContent>
            <TabsContent value="organizer" className="p-4">
              <OrganizerDetails />
            </TabsContent>
            <TabsContent hidden={!event || !event?.draft} value="version_comparer" className="p-4 h-[calc(100lvh_-_150px)] overflow-y-auto w-full mt-0">
              {event && <VersionComparer original={event} />}
            </TabsContent>
          </Tabs>
        </form>
      </Form>
    </div>
  );
}
