import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Filter, Plus, SortAsc, X } from 'lucide-react';
import { useEvents } from '../../event/api/getEvents';
import { useOrganizerStore } from '@/stores/organizerStore';
import { OrganizerEventListItem } from './organizer-event-list-item';
import { EventListSkeleton } from './EventListSkeleton';
import { EventStatus, LogCategory, TEvent } from '@/features/event/types';
import React, { useState } from 'react';
import { useCopyEvent } from '@/features/event/api/copy-event';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { cn } from '@/utils';
import { Input } from '@/components/ui/input';
import { Search } from 'react-bootstrap-icons';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useEventsFromPreviousYears } from '@/features/event/api/get-events-from-previous-years';
import { useYear } from '@/features/settings/api/get-year';
import { useMe } from '@/features/auth/api/get-me';
import { useOrganization } from '../api/getOrganization';
import { NewMainContactTerms } from './new-main-contact-termts';
import { useTimePeriodRestrictions } from '@/hooks/use-time-period-restrictions';
// import { useEventsFromPreviousYears } from '@/features/event/api/get-events-from-previous-years';
// import { CopyEventDialog } from './copy-event-dialog';

const statuses = {
  [EventStatus.Draft]: 'Draft',
  [EventStatus.ActionRequired]: 'ActionRequired',
  [EventStatus.AwaitingPayment]: 'AwaitingPayment',
  [EventStatus.Pending]: 'Pending',
  [EventStatus.Approved]: 'Approved',
  [EventStatus.Rejected]: 'Rejected',
  [EventStatus.Cancelled]: 'Cancelled'
  // [EventStatus.UnPublished]: 'UnPublished',
} as const;

export function OrganizerEventList() {
  const [sortyBy, setSortBy] = useState<string>('time');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [status, setStatus] = React.useState<EventStatus | null | 1000>(1000);
  const [filterByCreator, setFilterByCreator] = useState<string>('');
  const [filterByYear, setFilterByYear] = useState<number>(new Date().getFullYear());

  const organizerId = useOrganizerStore(state => state.organizationId);

  const { data: user } = useMe({
    config: {
      enabled: false
    }
  });

  const { data: organization } = useOrganization({
    organizerId,
    config: {
      enabled: !!organizerId
    }
  });

  const { data: activeYear } = useYear({
    config: {
      onSuccess: year => {
        setFilterByYear(year);
      }
    }
  });

  const { mutateAsync: copyEvent } = useCopyEvent({
    organizerId,
    config: {
      onSettled: () => {
        setStatus(EventStatus.Draft);
        setFilterByYear(activeYear as number)
      }
    }
  });

  const { data: previousEvents = [] } = useEventsFromPreviousYears({
    organizerId,
    config: {
      enabled: !!organizerId
    }
  });

  const { data: events = [], isLoading } = useEvents({
    organizerId,
    config: {
      enabled: !!organizerId
    }
  });

  const { isEventPeriodClosed } = useTimePeriodRestrictions();
  const creators = [...new Set(events?.flatMap(event => event?.logs?.filter(log => log.category === LogCategory.CREATED)?.map(log => log?.user?.username)))].filter(name => !!name) as string[];

  const filteredEvents = [...previousEvents, ...events]
    ?.filter(event => {
      const searchTermLower = searchTerm.toLowerCase();
      const matchesSearchTerm = event?.title?.toLowerCase().includes(searchTermLower) || event?.description?.toLowerCase().includes(searchTermLower) || event?.eventId?.toString()?.toLowerCase().includes(searchTermLower) || event?.draftParentId?.toString()?.toLowerCase().includes(searchTermLower);
      const matchesCreator = filterByCreator ? event?.logs?.some(log => log.category === LogCategory.CREATED && log.user?.username === filterByCreator) : true;

      const matchesYear = event?.yearId === filterByYear;

      return matchesSearchTerm && matchesCreator && matchesYear;
    })
    .sort((a: TEvent, b: TEvent) => {
      if (sortyBy === 'eventId') {
        return b.eventId - a.eventId;
      }
      if (sortyBy === 'title' && a.title && b.title) {
        return a.title.localeCompare(b.title);
      }
      if (sortyBy === 'time') {
        const earliestTimeA = a.times?.sort((a, b) => new Date(a.eventStart).getTime() - new Date(b.eventStart).getTime())[0];
        const earliestTimeB = b.times?.sort((a, b) => new Date(a.eventStart).getTime() - new Date(b.eventStart).getTime())[0];
        return new Date(earliestTimeA?.eventStart).getTime() - new Date(earliestTimeB?.eventStart).getTime();
      }
      return 0;
    });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setStatus(1000);
  };

  const years = [...new Set([...previousEvents.map(event => event.yearId), activeYear])].sort();

  const isMainContact = organization?.users?.some(({ mainContact, userId: _userId }) => user?.userId === _userId && mainContact);
  const hasApproval = isMainContact ? organization?.logs.some(log => log.category === 'ConfirmTerms' && log.logText?.toString() === activeYear?.toString() && log.userId === user?.userId) : true;
  const hasAdminApproval = organization?.logs.some(log => log.category === 'ConfirmTerms' && log.logText?.toString() === activeYear?.toString());

  if (!hasApproval && organization && isMainContact) {
    return <NewMainContactTerms organizer={organization} />;
  }

  return (
    <div className="space-y-4 md:space-y-6 flex flex-col h-full  w-full m-auto">
      <div className="flex flex-row  gap-2  w-full md:items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-medium text-foreground">
              <Label name="events_page_title" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </h3>
          </div>
        </div>
        {hasAdminApproval && (
          <div className="flex items-center space-x-2">
            <Link to="/events/create" className="flex items-center justify-center">
              <Button size="sm">
                <Plus className="h-4 w-4 mr-1" />
                <Label name="create_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
              </Button>
            </Link>
          </div>
        )}
      </div>
      <Separator />
      <div className="flex items-center gap-x-2">
        <div className="relative w-full">
          <Search className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 h-4 text-muted-foreground" />
          <Input className="pl-10" value={searchTerm} onChange={handleSearch} aria-label="Search" />
          {searchTerm?.length > 0 && (
            <Button variant="ghost" className="absolute right-3 h-8 w-8 top-1/2 transform p-1 rounded-full -translate-y-1/2" onClick={() => setSearchTerm('')}>
              <X className="w-4 h-4" />
            </Button>
          )}
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className={cn('bg-accent')}>
              <Filter className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-72">
            <DropdownMenuLabel>
              <Label name="created_by" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {creators?.map(creator => (
              <DropdownMenuCheckboxItem className="flex items-center gap-x-2" checked={filterByCreator === creator} onCheckedChange={_ => setFilterByCreator(filterByCreator === creator ? '' : creator)}>
                <Avatar className="w-6 h-6">
                  <AvatarImage />
                  <AvatarFallback className="bg-brand text-white uppercase">{creator?.substring(0, 1)}</AvatarFallback>
                </Avatar>
                {creator}
              </DropdownMenuCheckboxItem>
            ))}
            <Separator />
            <DropdownMenuLabel className="flex gap-x-2 items-center py-2.5">
              <SortAsc className="w-4 h-4" />
              <Label name="sort_by" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </DropdownMenuLabel>
            <Separator />
            <DropdownMenuCheckboxItem className="flex items-center gap-x-2" checked={sortyBy === 'eventId'} onCheckedChange={_ => setSortBy('eventId')}>
              <Label name="sort_by_eventId" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem className="flex items-center gap-x-2" checked={sortyBy === 'title'} onCheckedChange={_ => setSortBy('title')}>
              <Label name="sort_by_title" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem className="flex items-center gap-x-2" checked={sortyBy === 'time'} onCheckedChange={_ => setSortBy('time')}>
              <Label name="sort_by_time" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {previousEvents.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className={cn('bg-accent')}>
                År {filterByYear}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-30">
              <DropdownMenuLabel>
                <Label name="Year" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {years?.map(year => (
                <DropdownMenuCheckboxItem className="flex items-center gap-x-2" checked={filterByYear === year} onCheckedChange={_ => setFilterByYear(year as number)}>
                  {year}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      {!isLoading && (
        <div className="flex gap-2 flex-wrap text-sm">
          <Button variant={status === 1000 ? 'default' : 'outline'} size="sm" className={cn('rounded-full px-3 text-sm font-medium')} onClick={() => setStatus(1000)}>
            <Label name="all" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            <span className="ml-1">({filteredEvents?.length})</span>
          </Button>
          {Object.entries(statuses)
            .filter(([key]) => filteredEvents?.some(event => event.status.toString() === key))
            .map(([key, value]) => (
              <Button variant={status === Number(key) ? 'default' : 'outline'} size="sm" disabled={filteredEvents?.filter(event => event.status.toString() === key).length === 0} className={cn('rounded-full px-3 text-sm font-medium')} onClick={() => setStatus(Number(key) as EventStatus)} key={key}>
                <Label name={value} groupName={LabelGroup.ORGANIZER_EVENT_STATUSES} />
                <span className="ml-1">({filteredEvents?.filter(event => event.status.toString() === key).length})</span>
              </Button>
            ))}
        </div>
      )}
      <div className="space-y-2">
        {!isLoading ? (
          filteredEvents
            ?.filter(event => (status === 1000 ? true : status === null || event.status === status))
            ?.filter(event => (status === 1000 ? event.draftParentId === null : true))
            ?.map(event => <OrganizerEventListItem key={event.eventId} event={{ ...event, draft: events?.find(e => e.draftParentId === event.eventId) ?? null }} onCopy={hasAdminApproval && !isEventPeriodClosed ? copyEvent : null} />)
        ) : (
          <EventListSkeleton />
        )}
      </div>
    </div>
  );
}
