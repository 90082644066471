import { FormDescription, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { label } from '@/utils/label';
import { EventPerson } from '../types';
import { Button } from '@/components/ui/button';
import { Plus, Trash2 } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { SWEDISH_PARTIES } from '../data/swedish_parties';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { EventPersonSuggestionInput } from './event-person-suggestion-input';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import { useMe } from '@/features/auth/api/get-me';

interface EventPersonInputProps {
  form: any;
  field: any;
}

const DEFAULT_PERSON = {
  eventPersonId: -1,
  firstName: '',
  lastName: '',
  title: '',
  party: undefined,
  organizationName: ''
};

export function EventPersonInput({ field, form }: EventPersonInputProps) {
  const eventId = form.getValues('eventId');
  const eventPersons = (field.value?.length === 0 ? [{ ...DEFAULT_PERSON, eventId }] : field.value) ?? [];

  const handleAddEventPerson = () => {
    const newEventPersonIds =
      form
        .getValues('persons')
        .filter((eventPerson: EventPerson) => eventPerson.eventPersonId < 0)
        .map((eventPerson: EventPerson) => eventPerson.eventPersonId) || [];

    const newEventPersonId = newEventPersonIds.length > 0 ? (Math.min(...newEventPersonIds) ?? 0) - 1 : -1;

    field.onChange([...(eventPersons ?? []), { ...DEFAULT_PERSON, eventPersonId: newEventPersonId, eventId }]);
  };

  const handleDeleteEventPerson = (eventPersonId: number) => {
    if (field.value.length === 1) {
      field.onChange([]);
      return;
    } else {
      field.onChange(field.value?.filter((Person: any) => Person.eventPersonId !== eventPersonId));
    }
  };

  const handleUpdateEventPerson = (eventPersonId: number, value: string, prop: string) => {
    const valueCopy = [...eventPersons];
    const index = eventPersons?.findIndex((Person: any) => Person.eventPersonId === eventPersonId);

    if (index === -1) return console.error('EventPersonId not found');

    valueCopy[index][prop] = value;

    field.onChange(valueCopy);
  };

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <div className="space-y-6">
      <FormDescription>
        <Label name="event_person_description" groupName={labelGroup} />
      </FormDescription>
      <div className="space-y-6">
        {eventPersons?.map((person: any) => (
          <>
            <div key={person.eventPersonId} className="grid md:grid-cols-3 gap-4 items-center">
              <div className="space-y-2">
                <FormLabel className="font-semibold">
                  <Label name="event_person_firstname" groupName={labelGroup} />
                </FormLabel>
                <EventPersonSuggestionInput
                  propName="firstName"
                  handleSelectSuggestion={(suggestion: EventPerson) => {
                    handleUpdateEventPerson(person.eventPersonId, suggestion.firstName, 'firstName');
                    handleUpdateEventPerson(person.eventPersonId, suggestion.lastName, 'lastName');
                    handleUpdateEventPerson(person.eventPersonId, suggestion.title, 'title');
                    handleUpdateEventPerson(person.eventPersonId, suggestion.organizationName, 'organizationName');
                    handleUpdateEventPerson(person.eventPersonId, suggestion.party ?? 'none', 'party');
                  }}
                  handleUpdateEventPerson={handleUpdateEventPerson}
                  person={person}
                />
              </div>

              <div className="space-y-2">
                <FormLabel className="font-semibold">
                  <Label name="event_person_title" groupName={labelGroup} />
                </FormLabel>
                <Input value={person.title} onChange={e => handleUpdateEventPerson(person.eventPersonId, e.target.value, 'title')} className="w-full" />
              </div>

              <div className="space-y-2">
                <FormLabel className="font-semibold">
                  <Label name="event_person_organization_name" groupName={labelGroup} />
                </FormLabel>
                <Input value={person.organizationName} onChange={e => handleUpdateEventPerson(person.eventPersonId, e.target.value, 'organizationName')} className="w-full" />
              </div>
            </div>
            <div className="flex items-end justify-between w-full">
              <div className="space-y-2 w-1/2 pr-2">
                {person.party !== undefined && person.party !== 'none'  && (
                  <FormLabel className="font-semibold">
                    <Label name="event_person_party" groupName={labelGroup} />
                  </FormLabel>
                )}
                {person.party !== undefined && person.party !== 'none' ? (
                  <Select onValueChange={value => handleUpdateEventPerson(person.eventPersonId, value, 'party')} value={person.party}>
                    <SelectTrigger className="max-w-full w-full">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {[
                        {
                          name: label('event_person_party_none', labelGroup),
                          abbreviation: 'none'
                        },
                        ...SWEDISH_PARTIES.sort((a, b) => a.name.localeCompare(b.name)),
                        {
                          name: label('event_person_party_other', labelGroup),
                          abbreviation: 'other'
                        }
                      ].map(({ abbreviation, name }) => (
                        <SelectItem key={abbreviation} value={abbreviation}>
                          <Label name={name} groupName={labelGroup} />
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ) : (
                  <div className="items-top flex space-x-2">
                    <Checkbox id={'no-political-involvement' + person.eventPersonId} onCheckedChange={checked => handleUpdateEventPerson(person.eventPersonId, !checked ? 'none' : '', 'party')} />
                    <div className="grid gap-1.5 leading-none">
                      <label htmlFor={'no-political-involvement' + person.eventPersonId} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        <Label name="event_person_party" groupName={labelGroup} />
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {(person?.organizationName !== '' || person?.title !== '' || person?.firstName !== '' || person?.lastName !== '' || eventPersons.length > 1) && (
                <div className="">
                  <Button variant="outline" size="sm" type="button" onClick={() => handleDeleteEventPerson(person.eventPersonId)}>
                    <Trash2 className="w-4 h-4 mr-1" />
                    <Label name="delete" groupName={LabelGroup.GLOBAL} />
                  </Button>
                </div>
              )}
            </div>

            <Separator />
          </>
        ))}
      </div>

      <Button type="button" variant="outline" onClick={handleAddEventPerson}>
        <Plus className="w-4 h-4 mr-2" />
        <Label name="add_event_person_button_text" groupName={labelGroup} />
      </Button>
    </div>
  );
}
