import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TEvent } from '@/features/event/types';

const QUERY_KEY = 'event';

export const getEvent = async ({ eventId }: { eventId?: number }): Promise<TEvent> => {
  return axios.get(`/manager/event/${eventId}`);
};

type QueryFnType = typeof getEvent;

type UseEventOptions = {
  eventId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useEvent = ({ eventId, config }: UseEventOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY, eventId],
    queryFn: () => getEvent({ eventId }),
  });
};
