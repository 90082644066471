import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { DataTable } from '@/components/ui/data-table';
import { useArchivedEvents } from '@/features/admin/api/get-archived-events';
import { columns } from '@/features/admin/components/event-columns';
import { EventFilters } from '@/features/admin/components/(filters)/event-filters';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

import { ManagerEvent } from '@/features/admin/types';
import { Row } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';

export function ArchivedEventsRoute() {
  const { data: archivedEvents = [], isLoading } = useArchivedEvents({
    config: {}
  });

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-4 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="arhived_events" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          <DataTable
            columns={[
              {
                accessorKey: 'yearId',
                header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="year" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />
              },
              ...columns
            ]}
            data={archivedEvents}
            columnVisibility={{
              category: false,
              organizerContactEmail: false
            }}
            config={{
              maxHeight: 'calc(100lvh - 290px)',
              getSearchFields: row => [
                (row as Row<ManagerEvent>).original.eventId.toString(),
                (row as Row<ManagerEvent>).original?.draftParentId?.toString() ?? '',
                (row as Row<ManagerEvent>).original.title,
                (row as Row<ManagerEvent>).original.organizerName,
                (row as Row<ManagerEvent>).original.yearId?.toString() ?? '',
                (row as Row<ManagerEvent>)?.original.organizerContactEmail ?? ''
              ]
            }}
            FacetedFilters={EventFilters}
          />
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
