import { Button } from '@/components/ui/button';
import { cn } from '@/utils';
import { useEffect, useState } from 'react';
import { sections } from '../data/sections';
import { ArrowDown, ArrowRight, Files } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useFormContext, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ArrowLeft, ExclamationCircle, ListUl } from 'react-bootstrap-icons';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Link } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { EventCategory, EventStatus, TEvent } from '../types';
import { label } from '@/utils/label';
import { sendEventSchema } from '../data/send-event-schema';
import { scrollToMiddle } from '../utils/scroll-to-middle';
import { Separator } from '@/components/ui/separator';
import { baseEventSchema } from '../data/base-event-schema';
import { useTimePeriodRestrictions } from '@/hooks/use-time-period-restrictions';
import { useYear } from '@/features/settings/api/get-year';

interface SidebarProps {
  isUpdating: boolean;
  isLocked?: boolean;
  event?: TEvent | null;
}

export function Sidebar({ isUpdating, isLocked, event = null }: SidebarProps) {
  const [activeSectionId, setActiveSectionId] = useState('');
  const [errors, setErrors] = useState<any[]>([]);

  const form = useFormContext();

  const formValues = useWatch({ control: form.control });
  const submitCount = form.formState.submitCount;

  const { data: activeYear } = useYear({});

  const isSending = form.watch('sendToReview');

  const schema = (() => {
    if (!isSending && form.watch('status') === EventStatus.Draft) {
      return baseEventSchema;
    }
    return sendEventSchema;
  })();

  useEffect(() => {
    if (formValues && submitCount > 0) {
      try {
        schema.parse(formValues);
        setErrors([]);
      } catch (err) {
        if (err instanceof z.ZodError) {
          const messages = err.errors.map(error => ({
            message: error.message,
            id: (
              error as z.ZodIssue & {
                params: { id: string };
              }
            )?.params?.id,
            section: (
              error as z.ZodIssue & {
                params: { section: string };
              }
            )?.params?.section
          }));

          setErrors(messages);
        } else {
          console.error(err);
        }
      }
    }
  }, [formValues, submitCount, schema]);

  // Effect to update active section based on scroll position
  useEffect(() => {
    const sectionElements = sections.map(section => document.querySelector(`#${section.id}`));

    const observer = new IntersectionObserver(
      entries => {
        // Filter out entries that are not intersecting
        const intersectingEntries = entries.filter(entry => entry.isIntersecting);

        // Sort the intersecting entries by their top position (the closest to the top will be first)
        intersectingEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);

        if (intersectingEntries.length > 0) {
          // Set the active section to the one closest to the top
          setActiveSectionId(intersectingEntries[0].target.id);

          const menuItem = document.getElementById('section-menu-item-' + intersectingEntries[0].target.id);

          if (menuItem) {
            menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 0px 0px', // Adjust rootMargin if needed
        threshold: 0.3 // Trigger when 10% of the section is visible
      }
    );

    sectionElements.forEach(sectionElement => {
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      // Cleanup observers when the component unmounts
      sectionElements.forEach(sectionElement => {
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, [sections, activeSectionId]);


  const filteredSections = sections.filter(({ id }) => (id === 'persons-section' && form.watch('category') === EventCategory.TRAFFPUNKT ? false : true));
  const hasEditPermission = form.watch('status') !== EventStatus.Pending && form.watch('status') !== EventStatus.Cancelled

  const { isEventPeriodClosed } = useTimePeriodRestrictions();

  const activeComments = [event?.descriptionComment, event?.titleComment, event?.socialIssueComment].filter(Boolean);

  return (
    <div className="md:w-full lg:w-[360px] px-4 relative space-y-4 py-4">
      {form.watch('eventId') > 0 ? (
        <div className="text-2xl font-bold font-header flex flex-col items-start gap-2">
          {event?.draftParentId && (
            <div className="bg-brand/10 text-orange-900 text-sm font-semibold font-base flex items-center w-fit p-2 gap-2 px-2 rounded-md">
              <Files className="h-4 w-4  stroke-brand" />
              <Label name="event_draft_version" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </div>
          )}
          <div className="flex gap-x-2 text-2xl items-center">
            <Label name="event_id_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} /> {event?.draftParentId ?? event?.eventId}
          </div>
        </div>
      ) : null}
      <div className="flex items-center gap-2 flex-col w-full">
        <Link to="/events" className="w-full">
          <Button variant="outline" size="sm" type="button" className="w-full">
            <ArrowLeft className="h-4 w-4 mr-1" />
            <Label name="back_to_event_list_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </Button>
        </Link>

        {(!isEventPeriodClosed || event?.status === EventStatus.Approved || !!event?.draftParentId) && (event?.eventId ? event?.yearId === activeYear : true) && hasEditPermission && (
          <Button size="sm" disabled={isUpdating || !form.formState.isDirty || isLocked} onClick={() => form.setValue('sendToReview', false)} className="w-full" type="submit">
            {isUpdating && !form.watch('sendToReview') && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
            <Label name="event_save_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </Button>
        )}

        {(!isEventPeriodClosed || event?.status === EventStatus.Approved || !!event?.draftParentId) && (event?.eventId ? event?.yearId === activeYear : true) && hasEditPermission && (
          <>
            <Separator />
            <Button size="sm" disabled={isLocked} variant="outline" onClick={() => scrollToMiddle('send-section')} className="w-full bg-brand border-0 text-white hover:bg-brand/70 hover:text-white" type="button">
              <ArrowDown className="h-4 w-4 mr-2" />
              <Label name="go_to_send" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          </>
        )}
      </div>
      <Accordion type="single" collapsible defaultValue="navigation-list">
        <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="navigation-list">
          <AccordionTrigger className="p-0 text-foreground">
            <div className="mb-0 text-lg font-semibold text-foreground  p-4 items-center flex ">
              <ListUl className="h-5 w-5 mr-2" />
              <Label name="event_navigation_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className={cn('flex flex-col border border-zinc-200 rounded-md overflow-hidden border-y-zinc-300 divide-y divide-zinc-300 ', !hasEditPermission || isLocked ? 'opacity-50 pointer-events-none' : '')}>
              {filteredSections.map(section => (
                <div key={section.id} id={'section-menu-item-' + section.id} className={cn('hover:bg-gray-light  bg-card ')}>
                  <div className={cn('flex flex-col w-full gap-4 p-4  border-l-4', activeSectionId === section.id ? 'border-l-[hsl(var(--brand))]' : 'border-l-[zinc-50]')}>
                    <div className="flex items-center gap-x-2">
                      <div role="navigation" onClick={() => scrollToMiddle(section.id)} className={cn('flex items-center justify-between w-full font-medium text-sm cursor-pointer')}>
                        <div>
                          <div className="flex gap-1 items-center">
                            <Label name={'section_menu_' + section.title} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />

                            {errors?.some(error => error.section === section.id) && <ExclamationCircle className="h-4 w-4 text-amber-500" />}
                          </div>
                          {section.id === 'content-section' && activeComments?.length > 0 && (
                            <span className="ml-2">
                              <Label name="has_comments" groupName={LabelGroup.ORGANIZER_EVENT_LIST} /> ({activeComments.length})
                            </span>
                          )}
                        </div>

                        <ArrowRight className="h-4 w-4" />
                      </div>
                    </div>

                    {errors?.some(error => error.section === section.id) && (
                      <Alert className="bg-amber-50 [&>svg]:text-amber-500 px-6 border-amber-200  text-amber-900">
                        <AlertTitle className="text-sm">
                          <Label name="remedy_issues" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                        </AlertTitle>
                        <AlertDescription className="flex flex-col py-2 pl-4">
                          <ul className="list-disc ">
                            {errors
                              ?.filter(error => error.section === section.id)
                              ?.map(validation => (
                                <li className="underline cursor-pointer" role="navigation" onClick={() => scrollToMiddle(section.id)} key={validation.message}>
                                  {label(validation.message as string, LabelGroup.ORGANIZER_EVENT_FORM)}
                                </li>
                              ))}
                          </ul>
                        </AlertDescription>
                      </Alert>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
