import { z } from 'zod';

const passwordSchema = z.string()
  .min(6, { message: 'password_min_length_validation_text' })
  .refine(
    (value) => /[A-Za-z]/.test(value) && /\d/.test(value),
    { message: 'password_alpha_numeric_validation_text' }
  );

export { passwordSchema };
