import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { validateUrl } from '@/utils/validate-url';
import { z } from 'zod';

const basicDetailsSchema = z.object({
  organizationType: z.string().optional(),
  eventType: z.string().optional(),
  category: z.string().refine(value => value.length > 0 || !!value, {
    message: 'category_is_required',
    params: { id: 'event_category', section: 'category-section' },
    path: ['category'] // Optional: Specify the path to the field in case of nested schemas
  }),
  topic: z.string().optional(),
  topic2: z.string().optional(),
  languages: z.array(z.string()).optional(),
  times: z
    .array(
      z.object({
        eventTimeId: z.number(),
        eventId: z.number(),
        eventStart: z.union([z.string(), z.date()]).optional(),
        eventEnd: z.union([z.string(), z.date()]).optional(),
        inserted: z.string().optional(),
        updated: z.string().optional()
      })
    )

    .refine(
      values => {
        if (values.length < 2) {
          return true; // No need to check if there are less than 2 events
        }

        function doEventsOverlap(start1: Date, end1: Date, start2: Date, end2: Date) {
          return !(end1 <= start2 || end2 <= start1);
        }

        for (let i = 0; i < values.length; i++) {
          for (let j = i + 1; j < values.length; j++) {
            const event1Start = new Date(values[i].eventStart || '');
            const event1End = new Date(values[i].eventEnd || '');
            const event2Start = new Date(values[j].eventStart || '');
            const event2End = new Date(values[j].eventEnd || '');

            // Check if events overlap
            if (doEventsOverlap(event1Start, event1End, event2Start, event2End)) {
              return false; // Events overlap, validation fails
            }
          }
        }

        // All checks passed
        return true;
      },
      {
        message: 'times_conflicted_validation_text',
        params: { id: 'event_time', section: 'basic-details-section' },
        path: ['times'] // Optional: Specify the path to the field in case of nested schemas
      }
    )
});

const locationSchema = z.object({
  locationId: z.number().optional(),
  locationDescription: z.string().optional()
});

const contentSchema = z.object({
  title_lastVersion: z.string().optional(),
  title: z.string().refine(value => value.length > 0 || !!value, {
    message: 'title_is_required',
    params: { id: 'event_title', section: 'content-section' },
    path: ['title'] // Optional: Specify the path to the field in case of nested schemas
  }),
  socialIssue: z.string().refine(value => value.length > 0 || !!value || value === undefined, {
    message: 'social_issue_is_required',
    params: { id: 'social_issue', section: 'content-section' },
    path: ['socialIssue'] // Optional: Specify the path to the field in case of nested schemas
  }),
  socialIssue_lastVersion: z.string().optional(),
  description_lastVersion: z.string().optional(),
  description: z
    .string()
    .max(1000, {
      message: 'description_max_validation_text'
    })
    .optional()
});

const organizerSchema = z.object({
  organizers: z
    .array(
      z.object({
        eventId: z.number(),
        eventOrganizerId: z.number().optional(),
        organizerName: z.string(),
        inserted: z.string().optional(),
        updated: z.string().optional()
      })
    )
    .optional(),
  contactPerson1Name: z.string().optional(),
  contactPerson1Organization: z
    .string()
    .refine(value => value.length > 0, {
      message: 'contact_person1_organization_is_required',
      params: { id: 'contact_person1_organization', section: 'contact-section' },
      path: ['contactPerson1Organization'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  contactPerson1Telephone: z
    .string()
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'invalid_telephone_format_contact_person1_telephone',
      params: { id: 'contact_person1_telephone', section: 'contact-section' },
      path: ['contactPerson1Telephone'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  contactPerson1Email: z
    .string()
    .refine(value => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
      message: 'invalid_email_format_contact_person1_email',
      params: { id: 'contact_person1_email', section: 'contact-section' },
      path: ['contactPerson1Email']
    })
    .optional(),
  persons: z.array(
    z.object({
      eventId: z.number().optional(),
      eventPersonId: z.number(),
      firstName: z.string(),
      lastName: z.string().optional(),
      title: z.string().optional(),
      organizationName: z.string().optional(),
      party: z.string().optional().nullable(),
      inserted: z.string().optional(),
      updated: z.string().optional()
    })
  ),
  contactPerson1Title: z.string().optional(),
  contactPerson2Name: z.string().optional(),
  contactPerson2Title: z.string().optional(),
  contactPerson2Organization: z.string().optional(),
  contactPerson2Telephone: z
    .string()
    .optional()
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'invalid_telephone_format_contact_person2_telephone',
      params: { id: 'contact_person2_telephone', section: 'contact-section' },
      path: ['contactPerson2Telephone'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  contactPerson2Email: z.string().refine(value => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
    message: 'invalid_email_format_contact_person2_email',
    params: { id: 'contact_person2_email', section: 'contact-section' },
    path: ['contactPerson2Email']
  }),
  showEmail: z.boolean().optional(),
  showTelephone: z.boolean().optional(),
  url1: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_url1',
      params: { id: 'url1', section: 'web-links-section' },
      path: ['url1'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  url2: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_url2',
      params: { id: 'url2', section: 'web-links-section' },
      path: ['url2'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  url3: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_url3',
      params: { id: 'url3', section: 'web-links-section' },
      path: ['url3'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  facebookUrl: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_facebook_url',
      params: { id: 'facebookUrl', section: 'web-links-section' },
      path: ['facebookUrl'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  xurl: z.string().optional(),
  linkedInUrl: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_linkedIn_url',
      params: { id: 'linkedInUrl', section: 'web-links-section' },
      path: ['linkedInUrl'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal(''))
});

const digitalEventSchema = z.object({
  digitalStreamingType: z.string().optional(),
  digitalStreamingUrl: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_streaming_url',
      params: { id: 'digitalStreamingUrl', section: 'streaming-section' },
      path: ['digitalStreamingUrl'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  digitalMeetingUrl: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_digital_meeting_url',
      params: { id: 'digitalMeetingUrl', section: 'digital-event-section' },
      path: ['digitalMeetingUrl'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  digitalMeetingDescription: z
    .string()
    .max(150, {
      message: 'digital_meeting_description_max_validation_text'
    })
    .optional(),
  digitalArchiveUrl: z
    .string()
    .optional()
    .refine(value => !value || validateUrl(value), {
      message: 'invalid_url_format_archive_url',
      params: { id: 'digitalArchiveUrl', section: 'archive-section' },
      path: ['digitalArchiveUrl'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal(''))
});

const accessabilitySchema = z.object({
  accessibilityText: z.boolean().optional(),
  accessibilityTeleloop: z.boolean().optional(),
  accessibilityVisualInterpretation: z.boolean().optional(),
  accessibilityWheelchairToilet: z.boolean().optional(),
  accessibilityWheelchairVenue: z.boolean().optional(),
  accessibilitySignLanguage: z.boolean().optional()
});

const environmentalDiplomaSchema = z.object({
  environmentStationary: z.boolean().optional(),
  environmentPrint: z.boolean().optional(),
  environmentFlyer: z.boolean().optional(),
  environmentBattery: z.boolean().optional(),
  environmentPlastic: z.boolean().optional(),
  environmentRecycling: z.boolean().optional(),
  environmentDisposable: z.boolean().optional(),
  environmentSourceSorting: z.boolean().optional(),
  environmentFoodEcological: z.boolean().optional(),
  environmentFoodLocallyProduced: z.boolean().optional(),
  environmentFoodEthical: z.boolean().optional(),
  environmentWater: z.boolean().optional(),
  environmentNoFood: z.boolean().optional(),
  environmentFood: z.boolean().optional(),
  environmentServiceQuestion: z.boolean().optional(),
  environmentServiceElectricity: z.boolean().optional(),
  environmentServiceTravel: z.boolean().optional(),
  environmentServiceCooking: z.boolean().optional()
});

export const baseEventSchema = z.object({
  eventId: z.number().nullable(),
  ...basicDetailsSchema.shape,
  ...locationSchema.shape,
  ...contentSchema.shape,
  ...organizerSchema.shape,
  ...digitalEventSchema.shape,
  ...accessabilitySchema.shape,
  ...environmentalDiplomaSchema.shape,
  yearId: z.number().optional(),
  status: z.number(),
  sendToReview: z.boolean().optional(),
  inserted: z.string().optional()
});
