import { Link, useLocation } from 'react-router-dom';
import { Building2, CalendarDays, ChevronsUpDown, File, FileClock, LayoutDashboard, LogOut, MapPin, Settings, Settings2, Text, Users, Wallet } from 'lucide-react';
import storage from '@/utils/storage';
import { UserType } from '@/features/user';
import { Logo } from '@/components/logo';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';
import { Sidebar as SidebarComponent, SidebarContent, SidebarFooter, SidebarGroup, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarTrigger } from '@/components/ui/sidebar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { logout } from '@/features/auth/api/logout';
import { useYear } from '@/features/settings/api/get-year';
import { cn } from '@/utils';

const managerRoutes = [
  {
    title: 'dashboard',
    path: 'dashboard',
    icon: LayoutDashboard,
    variant: 'ghost'
  },

  {
    title: 'events',
    path: 'events',
    icon: CalendarDays,
    variant: 'ghost' as any
  },
  {
    title: 'organizers',
    path: 'organizations',
    icon: Building2,
    variant: 'ghost' as any
  },
  {
    title: 'payments',
    path: 'payments',
    icon: Wallet,
    variant: 'ghost' as any
  },
  {
    title: 'archived_events',
    path: 'archive/events',
    icon: FileClock,
    variant: 'ghost' as any
  },
];

const adminRoutes = [
  {
    title: 'dashboard',
    path: 'dashboard',
    icon: LayoutDashboard,
    variant: 'ghost'
  },
  {
    title: 'events',
    path: 'events',
    icon: CalendarDays,
    variant: 'ghost' as any
  },
  {
    title: 'organizers',
    path: 'organizations',
    icon: Building2,
    variant: 'ghost' as any
  },
  {
    title: 'payments',
    path: 'payments',
    icon: Wallet,
    variant: 'ghost' as any
  },
  {
    title: 'archived_events',
    path: 'archive/events',
    icon: FileClock,
    variant: 'ghost' as any
  },
  {
    title: 'invoice_export',
    path: 'invoice-export',
    icon: File,
    variant: 'ghost' as any
  },
  {
    title: 'locations',
    path: 'locations',
    icon: MapPin,
    variant: 'ghost' as any
  },
  {
    title: 'users',
    path: 'users',
    icon: Users,
    variant: 'ghost' as any
  },
  {
    title: 'settings',
    path: 'settings',
    icon: Settings,
    variant: 'ghost' as any
  },
  {
    title: 'system_labels',
    path: 'labels',
    icon: Text,
    variant: 'ghost' as any
  },
] as const;

export function Sidebar() {
  const { pathname } = useLocation();

  const { data: user } = useMe({ config: { enabled: !!storage.getAccessToken() } });
  const { data: activeYear } = useYear({ config: { enabled: !!storage.getAccessToken() } });

  const role = user?.role;

  const routes = role === UserType.ADMIN ? adminRoutes : managerRoutes;

  return (
    <SidebarComponent collapsible="icon" className="sidebar">
      <SidebarHeader className="relative ">
        <div className="flex w-full  p-2 gap-x-2 items-center logo-group">
          <div className="flex aspect-square size-8 items-center justify-center rounded-lg  text-sidebar-primary-foreground">
            <Logo />
          </div>
          <div className="p-1 px-3  text-sm bg-sky-700  text-white w-fit rounded-md active-year">Aktivt år {activeYear?.toString()}</div>
        </div>

        <SidebarTrigger className="absolute -right-12 bg-white text-primary border border-input  sidebar-trigger opacity-0 hover:opacity-100" />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {routes.map(link => (
              <SidebarMenuItem key={link.title}>
                <SidebarMenuButton asChild className={cn(pathname.includes(link.path) ? 'bg-brand/90 hover:bg-brand/70' : 'hover:bg-gray-medium')}>
                  <Link to={link.path}>
                    <link.icon className="h-4 w-4" />
                    <Label name={link.title} groupName={LabelGroup.ADMIN_MENU} />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton size="lg" className="data-[state=open]:bg-sidebar-accent bg-gray-medium data-[state=open]:text-sidebar-accent-foreground">
                  <Avatar className="h-8 w-8 rounded-lg">
                    <AvatarImage />
                    <AvatarFallback className="rounded-lg bg-brand text-white">{(user?.name !== '' ? user?.name : user?.username)?.substring(0, 2)?.toUpperCase()}</AvatarFallback>
                  </Avatar>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{user?.name !== '' ? user?.name : user?.username?.substring(0, user?.username?.indexOf('@'))}</span>
                    <span className="truncate text-xs">{user?.username}</span>
                  </div>
                  <ChevronsUpDown className="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg text-sm" side="bottom" align="end" sideOffset={4}>
                <DropdownMenuLabel className="p-0 font-normal">
                  <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarImage />
                      <AvatarFallback className="rounded-lg bg-brand text-white">{(user?.name !== '' ? user?.name : user?.username)?.substring(0, 2)?.toUpperCase()}</AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">{user?.name !== '' ? user?.name : user?.username?.substring(0, user?.username?.indexOf('@'))}</span>
                      <span className="truncate text-xs">{user?.username}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />

                <Link to={`/profile`}>
                  <DropdownMenuItem>
                    <Settings2 className="w-4 h-4 mr-2" />
                    <Label name="profile_menu_item_text" groupName={LabelGroup.ADMIN_MENU} />
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={logout}>
                  <LogOut className="w-4 h-4 mr-2" />
                  <Label name="logout_menu_item_text" groupName={LabelGroup.ADMIN_MENU} />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </SidebarComponent>
  );
}
