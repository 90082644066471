import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TLocation } from '../types';

const QUERY_KEY = 'locations';

export const getLocations = ({ active }: { active?: boolean } = {}): Promise<TLocation[]> => {
  if (active) return axios.get(`/location/active`);
  return axios.get(`/location`);
};

type QueryFnType = typeof getLocations;

type UseLocationsOptions = {
  config?: QueryConfig<QueryFnType>;
  active?: boolean;
};

export const useLocations = ({ active, config }: UseLocationsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getLocations({ active }),
    ...config,
  });
};
